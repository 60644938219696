/**
 * @generated SignedSource<<22277781511be214c922836bb42d19ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DashboardMigrationModalMutation$variables = Record<PropertyKey, never>;
export type DashboardMigrationModalMutation$data = {
  readonly migrateDashboard: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly organization: {
        readonly dashboard: {
          readonly published: boolean;
        } | null;
        readonly forYouDashboard: {
          readonly app: {
            readonly customAppTitle: string | null;
          } | null;
          readonly published: boolean;
        } | null;
      };
    } | null;
  };
};
export type DashboardMigrationModalMutation = {
  response: DashboardMigrationModalMutation$data;
  variables: DashboardMigrationModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardMigrationModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MigrateDashboardResponse",
        "kind": "LinkedField",
        "name": "migrateDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "forYouDashboard",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductApp",
                        "kind": "LinkedField",
                        "name": "app",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "dashboard",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardMigrationModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MigrateDashboardResponse",
        "kind": "LinkedField",
        "name": "migrateDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "forYouDashboard",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductApp",
                        "kind": "LinkedField",
                        "name": "app",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "dashboard",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4306dea100c056594c785bdd8af7f0e",
    "id": null,
    "metadata": {},
    "name": "DashboardMigrationModalMutation",
    "operationKind": "mutation",
    "text": "mutation DashboardMigrationModalMutation {\n  migrateDashboard {\n    node {\n      organization {\n        forYouDashboard {\n          published\n          app {\n            customAppTitle\n            id\n          }\n          id\n        }\n        dashboard {\n          published\n          id\n        }\n        id\n      }\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81e85c8871aefc2987973f8d742a8807";

export default node;
