import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaDetails from "@/main/page/MetaDetails"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoIcon } from "@disco-ui"
import useDisclosure from "@utils/hook/useDisclosure"
import { graphql, useMutation } from "react-relay"
import { useHistory } from "react-router-dom"
import DashboardPageContent from "./DashboardPageContent"
import PublishForYouDashboardModal from "./for-you/PublishForYouDashboardModal"
import { ForYouDashboardMutation } from "./__generated__/ForYouDashboardMutation.graphql"

export default function ForYouDashboard() {
  const activeOrganization = useActiveOrganization()
  const history = useHistory()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [publishDashboard, isPublishing] = useMutation<ForYouDashboardMutation>(
    graphql`
      mutation ForYouDashboardMutation {
        publishForYouDashboard {
          node {
            organization {
              forYouDashboard {
                published
                app {
                  customAppTitle
                }
              }
              dashboard {
                published
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  if (!activeOrganization?.forYouDashboard) return null

  return (
    <MetaDetails
      metaDetailsForSharing={{
        title: activeOrganization.name,
        url: window.location.href,
        description: activeOrganization.description || "",
        coverPhoto: activeOrganization.cover || "",
      }}
      metaTitle={activeOrganization.name}
    >
      <DashboardPageContent
        dashboardId={activeOrganization.forYouDashboard.id}
        layout={activeOrganization.forYouDashboard.layout}
        HeaderProps={{
          appKey: activeOrganization.forYouDashboard.app,
          rightOptions: !activeOrganization.forYouDashboard.published && (
            <DiscoButton
              disabled={isPublishing}
              onClick={onOpen}
              leftIcon={<DiscoIcon icon={"iconsax.custom-rocket"} active />}
            >
              {"Publish Page"}
            </DiscoButton>
          ),
        }}
      />
      <PublishForYouDashboardModal
        isOpen={isOpen}
        onClose={onClose}
        publishDashboard={clickPublish}
      />
    </MetaDetails>
  )

  function clickPublish() {
    publishDashboard({
      variables: {},
      onError(error) {
        displayErrorToast(error)
      },
      onCompleted(response) {
        displaySuccessToast({
          message: `Published ${
            response.publishForYouDashboard.node?.organization.forYouDashboard?.app
              ?.customAppTitle || "For You"
          } dashboard successfully!`,
        })
      },
    })

    history.push(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
  }
}
