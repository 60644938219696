import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoAlert, DiscoButton, DiscoLink, DiscoText } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDisclosure from "@utils/hook/useDisclosure"
import ReactDOM from "react-dom"
import { useHistory, useLocation } from "react-router-dom"
import DashboardMigrationModal from "./DashboardMigrationModal"

export default function DashboardMigrationBanner() {
  const activeOrganization = useActiveOrganization()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const canManage = activeOrganization?.viewerPermissions.has("dashboard.manage")
  const location = useLocation()
  const isMobile = useIsMobile()
  const history = useHistory()
  const isWebView = useIsWebView()
  const classes = useStyles()

  // Only show the banner on the community home or for-you pages
  if (
    location.pathname !== ROUTE_NAMES.COMMUNITY.HOME.ROOT &&
    location.pathname !== ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU
  )
    return null

  if (!canManage || activeOrganization?.forYouDashboard?.published) return null

  const el = document.querySelector("#dashboard-migration-alert")
  if (!el) return null

  const onForYou = location.pathname.includes("/for-you")
  const hasForYouDashboard = activeOrganization?.forYouDashboard !== null

  return ReactDOM.createPortal(
    <>
      <DiscoAlert
        padding={2}
        message={renderAlertMessage()}
        cta={
          !onForYou &&
          !isMobile &&
          !isWebView &&
          hasForYouDashboard && (
            <DiscoButton
              testid={"DashboardPageContent.start-customizing"}
              variant={"outlined"}
              color={"grey"}
              onClick={startMigration}
            >
              {"Start Customizing"}
            </DiscoButton>
          )
        }
        classes={{
          root: classes.banner,
          icon: classes.alertIcon,
        }}
      />

      <DashboardMigrationModal isOpen={isOpen} onClose={onClose} />
    </>,
    el
  )

  function startMigration() {
    if (!activeOrganization?.forYouDashboard) {
      onOpen()
      return
    }

    history.push(ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU)
  }

  function renderAlertMessage() {
    if (onForYou) {
      return (
        <DiscoText color={"groovy.blue.600"}>
          {
            "This page is currently only visible to admins. Once published, this page will replace the "
          }

          <DiscoLink
            href={ROUTE_NAMES.COMMUNITY.HOME.ROOT}
            textDecoration={"underline"}
            textVariant={"body-md-600"}
          >
            {"home"}
          </DiscoLink>
          {" page and become the default page for your members"}
        </DiscoText>
      )
    }

    if (hasForYouDashboard) {
      return (
        <DiscoText color={"groovy.blue.600"}>
          {`We're making your '${
            activeOrganization?.forYouDashboard?.app?.customAppTitle || "For You"
          }' page even smarter and more personalized! The April 8 migration is now optional — publishing will replace your Home page, while keeping it in draft keeps your current Home experience unchanged. `}
        </DiscoText>
      )
    }

    return (
      <DiscoText color={"groovy.blue.600"}>
        {`We're making a new 'For You' page that's even smarter and more personalized! While we refine it, this feature is briefly unavailable — but your Home page remains fully functional and reliable.`}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  banner: {
    display: "flex",
    backgroundColor: theme.palette.groovy.blue[100],
    borderRadius: 0,
    alignItems: "center",
    ...styleIf(theme.palette.type === "dark", {
      "& p": {
        color: theme.palette.groovy.blue[600],
      },
    }),
  },
  alertIcon: {
    alignSelf: "flex-start",
    [theme.breakpoints.up("xl")]: {
      alignSelf: "center",
    },
    "& > svg": {
      color: theme.palette.groovy.blue[600],
    },
  },
}))
